/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="statistic-box">
      <div class="stats-section mb-5 mt-3">
        <div class="row">
          <div class="col-sm-6 col-lg mb-2">
            <azoli-value-box
              :showPercentage="true"
              :borderClass="'border-success border-start border-5'"
              label="Non affectable"
              :value="affectationStats.deInscriptionIncompleteCount"
              :percentageDivider="affectationStats.deAffectableCount"
            />
          </div>
          <div class="col-sm-6 col-lg mb-2">
            <azoli-value-box
              :showPercentage="true"
              :borderClass="'border-success border-start border-5'"
              label="Non affecté"
              :value="affectationStats.deNonAffeteCount"
              :percentageDivider="affectationStats.deAffectableCount"
            />
          </div>
          <div class="col-sm-6 col-lg mb-2">
            <azoli-value-box
              :showPercentage="true"
              :borderClass="'border-success border-start border-5'"
              label="Affecté à un conseillé"
              :value="affectationStats.deAffeteCount || 0"
              :percentageDivider="affectationStats.deAffectableCount"
            />
          </div>
          <!-- <div class="col-sm-6 col-lg mb-2">
              <azoli-value-box
                :showPercentage="true"
                :borderClass="'border-success border-start border-5'"
                label="Affecté à un conseillé"
                :value="femmeEnceinteCount"/>
            </div> -->
          <div class="col-sm-6 col-lg mb-2">
            <azoli-value-box
              :showPercentage="true"
              :borderClass="'border-success border-start border-5'"
              label="Nombre total"
              :value="affectationStats.deAffectableCount"
              :percentageDivider="affectationStats.deAffectableCount"
            />
            <!-- <azoli-value-box /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container-">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />

      <PDivider class="pb-0 mb-" />
      <PButton
        rounded
        class="px-3 p-button-sm p-button-rounded custom-chip primary me-2"
        :class="{ 'active primary': currentViewMode == opt.value }"
        v-for="(opt, i) in viewModeOptions"
        :key="i"
        @click="currentViewMode = opt.value"
        :label="opt.name"
      />
      <PDivider class="pb-0 mt-2" />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Gestionnaire de porte-feuille {{ mxTotalCount }}</h4>
        </div>
        <div v-if="$can('create', 'Azoli')" class="btn-group me-2">
          <DemandeurTransfertDialog
            size="lg"
            :item="activeTransfert"
            title="Affecter des DE à des CE/ULPE"
            @saved="transfertDemandeur"
            ref="conseillerSelector"
          >
            <template #action="{ show }">
              <a class="btn btn-warning" @click="showConseillerSelector(show)" href="#"
                >Affecter à des conseillers</a
              >
            </template>
          </DemandeurTransfertDialog>
        </div>
        <div v-if="$can('create', 'Azoli')" class="btn-group">
          <antenne-selector-dialog
            size="lg"
            title="Transert de DE vers une antenne"
            @saved="transfertDemandeurVersAntenne"
            ref="antenneSelector"
          >
            <template #action="{ show }">
              <a class="btn btn-warning" @click="show" href="#"
                >Affecter vers une antenne</a
              >
            </template>
          </antenne-selector-dialog>
        </div>
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <InscriptionIncompleteList
            v-if="currentViewMode == 'ALL_INSCRIPTION_IMCOMPLETE'"
            :hideStatistic="true"
            :hideHeader="true"
          />
          <AzoliBeneficiaireList
            v-if="
              currentViewMode != 'ALL_CE' &&
              currentViewMode != 'ALL_INSCRIPTION_IMCOMPLETE'
            "
            :extraQueryArg="getExtraQueryArg"
            :hideStatistic="true"
            :hideHeader="false"
            :hideTitle="true"
            :perideMode="true"
            :noPadding="true"
          />
          <div class="bg-white p-3 border" v-if="currentViewMode == 'ALL_CE'">
            <div class="bg-white rounded-4">
              <form class="bg-" method="post" accept-charset="utf-8" action="">
                <div class="row py-0 p-fluid grid formgrid">
                  <div class="col field">
                    <form class="d-flex">
                      <div class="input-group me-4">
                        <div class="p-inputgroup">
                          <!-- <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                      </span> -->
                          <PInputText
                            type="text"
                            v-model="mxSearch"
                            @keyup.enter.stop.prevent="submitQuery"
                            :placeholder="'Rechercher par nom prénom'"
                          />
                          <!-- <p-multi-select v-model="mxSearchFields" :options="searchFieldsOptions.filter(el => el.visible)" optionLabel="label" optionValue="value" :editable="true"/> -->
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="col-md-4 col-sm-12 d-flex">
                    <div class="input-group me-4">
                      <div class="p-inputgroup">
                        <!-- <p-dropdown
                          class="flex-grow- me-2"
                          v-model="typeAgent"
                          :options="typeAgentOptions"
                          optionLabel="label"
                          optionValue="value"
                          :placeholder="'Type Agent'"
                          display="chip"
                        /> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-auto col-sm-12 d-flex">
                    <div class="d-grid">
                      <a
                        href="#"
                        @click.prevent="submitQuery"
                        class="style_btn btn btn-primary"
                        id="button-query"
                        ><i class="bi bi-search"></i
                      ></a>
                    </div>
                  </div>
                  <div class="col-auto">
                    <button
                      type="button"
                      @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                      class="style_btn btn btn-warning"
                      id="button-query"
                    >
                      <i class="bi bi-filter"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <PDivider />
            <div class="table-responsive mt-md-1">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="single"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :sort-by.sync="mxPagination.sortBy"
                :sort-desc.sync="mxPagination.sortDesc"
                responsive="sm"
                sort-icon-left
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(id)="{ item: pec }">
                  <div class="d-flex justify-content- mb-3" v-if="pec">
                    PEC000{{ pec.id }}
                  </div>
                </template>
                <template #cell(typeDemandeur)="{ item: pec }">
                  <div class="d-flex justify- mb-3" v-if="pec">
                    <azoli-status-badge :de="pec.demandeur" />
                  </div>
                </template>
                <template #cell(deCount)="{ item: pec }">
                  <div class="d-flex justify- mb-3" v-if="pec">
                    <span class="text-primary">{{ pec.deFCount + pec.deMCount }}</span>
                  </div>
                </template>
                <template #cell(deNPECCount)="{ item: pec }">
                  <div class="d-flex justify- mb-3" v-if="pec">
                    <span class="text-danger">{{ pec.deNPECCount }}</span>
                  </div>
                </template>
                <template #cell(demandeur)="{ item: pec }">
                  <span v-if="pec && pec.demandeur" class="text-uppercase"
                    >{{ pec.demandeur.nom }} {{ pec.demandeur.prenom }}</span
                  >
                </template>
                <template #cell(classement)="{ item: pec }">
                  <span v-if="pec && pec.classement" class="text-uppercase">{{
                    pec.classement.libelle
                  }}</span>
                </template>
                <template #cell(creePar)="{ item: de }">
                  <span class="text-uppercase" v-if="de.creePar"
                    >{{ de.creePar.nom }} {{ de.creePar.prenom }}</span
                  >
                </template>
                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      v-tooltip.left="
                        'Accès au porte-feuille de ' + row.item.agent.nomPrenom
                      "
                      class="btn btn-sm py-1 btn-outline-primary"
                      @click.prevent="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>

                <template #row-details="row">
                  <div class="bg- pa-2">
                    <PTabView :activeIndex.sync="activeTab">
                      <div class="d-flex justify-content-between mt-2 mb-2">
                        <h4>
                          Portefeuille du {{ row.item.agent.fonction.nom.split("_")[0] }}
                          <span class="text-primary"
                            >{{ row.item.agent.nom }} {{ row.item.agent.prenom }}</span
                          >
                        </h4>
                        <a
                          href="#"
                          @click.prevent="selectConseillerForTransfer(row.item.agent)"
                          class="btn btn-warning"
                          >Affecter des DE</a
                        >
                      </div>
                      <PTabPanel header="Liste demandeurs">
                        <AzoliBeneficiaireList
                          v-if="activeTab == 0"
                          :extraQueryArg="`estSuiviParId=${row.item.agent.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Tâches plannifiées">
                        <ActiviteAnpeList
                          v-if="activeTab == 1"
                          :extraQueryArg="`agent=${row.item.agent.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Prise En Charge">
                        <PriseEnChargeList
                          v-if="activeTab == 2"
                          :agentId="row.item.agent.id"
                          :extraQueryArg="`agent=${row.item.agent.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Liste programmes">
                        <ParticipationProgrammeList
                          v-if="activeTab == 3"
                          :extraQueryArg="`creePar=${row.item.agent.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Consignes">
                        <ConsigneList
                          v-if="activeTab == 4"
                          :extraQueryArg="`creePar=${row.item.agent.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Prescriptions">
                        <PrescriptionList
                          v-if="activeTab == 5"
                          :extraQueryArg="`creePar=${row.item.agent.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Offres">
                        <OffreList
                          v-if="activeTab == 6"
                          :agentId="row.item.agent.id"
                          :extraQueryArg="`agent=${row.item.agent.id}`"
                        />
                      </PTabPanel>
                      <PTabPanel header="Entrepises">
                        <EntrepriseList
                          v-if="activeTab == 7"
                          :extraQueryArg="`creeParId=${row.item.agent.id}`"
                        />
                      </PTabPanel>
                      <!-- <PTabPanel header="Placements"> Content III </PTabPanel> -->
                      <!-- <PTabPanel header="Statistiques"> Content III </PTabPanel> -->
                    </PTabView>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{
                  (mxPagination.page <= 0 ? 0 : mxPagination.page - 1) *
                  mxPagination.rowsPerPage
                }}
                à
                {{
                  (mxPagination.page <= 0 ? 1 : mxPagination.page) *
                  mxPagination.rowsPerPage
                }}
                de {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <PDataTable
          :value="
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
              e;
            })
          "
        >
          <PColumn v-for="f in tableFields" :key="f.key" :field="f.key" :header="f.label">
            <template #body>
              <PSkeleton></PSkeleton>
            </template>
          </PColumn>
        </PDataTable>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import AzoliStatusBadge from "../../../components/common/AzoliStatusBadge.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import ActiviteStatistic from "../../../components/statistic/activite/ActiviteStatistic.vue";

import DemandeurTransfertDialog from "../../../components/espace/antenne/transfert/DemandeurTransfertDialog.vue";
import AntenneSelectorDialog from "../../../components/espace/antenne/transfert/AntenneSelectorDialog.vue";
// import * as XLSX from 'xlsx'
import AzoliBeneficiaireList from "./AzoliBeneficiaireList.vue";
import InscriptionIncompleteList from "./InscriptionIncompleteList.vue";
import ActiviteAnpeList from "./ActiviteAnpeList.vue";
import ConsigneList from "./ConsigneList.vue";
import PrescriptionList from "./PrescriptionList.vue";
import OffreList from "../entreprise/OffreList.vue";
// import ProgrammeList from './ProgrammeList.vue'
import PriseEnChargeList from "./PriseEnChargeList.vue";
import AzoliValueBox from "../../../components/statistic/AzoliValueBox.vue";

export default {
  components: {
    ActiviteStatistic,
    DemandeurTransfertDialog,
    AntenneSelectorDialog,
    AzoliBeneficiaireList,
    InscriptionIncompleteList,
    PriseEnChargeList,
    ActiviteAnpeList,
    ConsigneList,
    OffreList,
    PrescriptionList,
    AzoliStatusBadge,
    CAutoComplete,
    AzoliValueBox,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      activeTransfert: null,
      showAdvancedFilter: false,
      activeTab: 0,
      affectationStats: {
        deAffectableCount: 0,
        deAffeteCount: 0,
        deNonAffeteCount: 0,
        deInscriptionIncompleteCount: 50,
      },
      currentViewMode: "ALL_CE", //card, table
      viewModeOptions: [
        { name: "Tous CE/ULPE", value: "ALL_CE" },
        { name: "Tous DE", value: "ALL_DE" },
        { name: "Tous PPI", value: "ALL_PPI" },
        { name: "Tous INSTRUIT", value: "ALL_INSTRUIT" },
        { name: "Tous DE non affecté", value: "ALL_NON_AFFECTE" },
        { name: "Tous DE affecté", value: "ALL_AFFECTE" },
        // { name: 'Non clients', value: 'ALL_NON_CLIENT' },
        { name: "Inscription incomplete", value: "ALL_INSCRIPTION_IMCOMPLETE" },
      ],
      typeAgentOptions: [
        { label: "CA", value: "CA" },
        { label: "CE", value: "CE" },
        { label: "COACH_SWEDD", value: "COACH_SWEDD" },
        { label: "FE", value: "FE" },
        { label: "POINT_FOCAL", value: "POINT_FOCAL" },
        { label: "ULPE", value: "ULPE" },
      ],
      exportActions: [
        // {
        // 	label: 'PDF',
        // 	icon: 'pi pi-file-pdf',
        // 	command: () => {
        //     const data = XLSX.utils.json_to_sheet(this.tableData.map(el => ({ ...el, ...el.azoli })))
        //     const wb = XLSX.utils.book_new()
        //     XLSX.utils.book_append_sheet(wb, data, 'data')
        //     XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
        // 	}
        // },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
            // this.mxExportToExcel().then(items => {
            //   const data = XLSX.utils.json_to_sheet(items.map(el => ({
            //     ...el,
            //     ...el.azoli,
            //     commune: el.commune.name,
            //     departement: el.departement.name,
            //     village: el.ville.name,
            //     arrondissement: el.arrondissement.name,
            //   })))
            //   const wb = XLSX.utils.book_new()
            //   XLSX.utils.book_append_sheet(wb, data, 'data')
            //   XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
            // })
          },
        },
      ],
      searchFields: ["nomPrenom"],
      searchFieldsOptions: [
        {
          column: "nomPrenom",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          column: "creePar",
          value: "",
          label: "AE (Nom & prénom)",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
      ],
      filterOptions: [
        {
          label: "Agent",
          value: "nomPrenom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Téléphone",
          value: "phone",
          visible: this.$can("filter.telephone", "Azoli"),
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.loadAffectationStatistics();
    this.queryStatistics();
  },
  watch: {
    currentViewMode() {
      // localStorage.setItem('viewMode', this.currentViewMode)
    },
  },
  computed: {
    ...mapGetters({
      priseEnCharge: "demandeur/fetchDemandeurs",
      demandeurs: "demandeur/demandeurs",
      pagination: "demandeur/pagination",
    }),
    getExtraQueryArg() {
      let queryArg = "";
      // switch(this.currentViewMode){
      //   case 'ALL_DE':

      //     break;
      //   case 'ALL_PPI':
      //     queryArg=`regroupements=PPI`;
      //     break;
      //   case 'ALL_INSTRUIT':
      //     queryArg=`regroupements=INSTRUIT`;
      //     break;
      //   case 'ALL_INSCRIPTION_IMCOMPLETE':
      //   queryArg=`regroupements=INSTRUIT`;
      //     break;
      // }

      if (this.currentViewMode != "ALL_DE") {
        queryArg = `regroupements=${this.currentViewMode.replace("ALL_", "")}`;
      }
      return queryArg;
    },
    emptyTransferItem() {
      return {
        dossiers: [],
        agent: null,
        antenne: null,
      };
    },
    tableFields() {

      return [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "agent.nom", label: "Nom" },
        { key: "agent.prenom", label: "Prénom" },
        { key: "agent.sexe", label: "Sexe" },
        { key: "agent.fonction.nom", label: "Fonc." },
        // { key: 'agent', label: 'Téléphone' },
        { key: "deCount", label: "NbDE" },
        { key: "deFCount", label: "NbF" },
        { key: "deMCount", label: "NbM" },
        { key: "deNPECCount", label: "NbNPEC", value: 0 },
        { key: "deDENACount", label: "NbDENA" },
        { key: "deDESACount", label: "NbDESA" },
        { key: "deDEACount", label: "NbDEA" },
        // { key: 'deSALARIECount', label: 'NbSALARIE' },
        // { key: 'deINDEPENDANTCount', label: 'NbINDEP.' },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchAffectationDEStatistics: "statistic/fetchAffectationDEStatistics",
      fetchDemandeurs: "demandeur/fetchDemandeurs",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      downloadAzoli: "demandeur/downloadAzoli",
      fetchDossier: "demandeur/fetchDossier",
      validerDossier: "demandeur/validerDossier",
      transfererVersConseiller: "transfert/transfererVersConseiller",
      transfererVersAntenne: "transfert/transfererVersAntenne",
    }),
    loadAffectationStatistics() {
      this.fetchAffectationDEStatistics().then((data) => {
        this.affectationStats = data;
      });
    },
    toggleDetails(de) {
      console.log(de);
    },
    showConseillerSelector() {
      this.$refs.conseillerSelector.show();
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, demandeurId: de.id },
      });
    },
    selectConseillerForTransfer(agent) {
      this.activeTransfert = {
        ...this.emptyTransferItem,
        agent,
      };
      this.showConseillerSelector();
    },
    computeProgression(priseEnCharge) {
      let percentage = 0;

      if (priseEnCharge.classement) percentage = 25;

      if (priseEnCharge.statutProfessionnel) percentage = 50;

      if (priseEnCharge.cheminEmploi) percentage = 75;

      if (priseEnCharge.consignes.length > 0) percentage = 90;

      return percentage;
    },
    transfertDemandeur(transfer) {
      console.log("Agent: ", transfer);
      let { agent, dossiers, affecterAccompagnateurMicroprojet } = transfer;
      this.transfererVersConseiller({
        agent,
        affecterAccompagnateurMicroprojet,
        // demandeur: this.selected[0],
        dossiers,
      }).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
      });

      this.activeTransfert = null;
    },
    transfertDemandeurVersAntenne(transfer) {
      console.log("Transfert: ", transfer);
      let { antenne, dossiers } = transfer;
      this.transfererVersAntenne({
        antenne,
        dossiers,
      }).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
      });

      this.activeTransfert = null;
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    submitQuery() {
      this.mxSubmitQuery();
      //   this.$refs.deStatistic.reload();
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "agent",
        search: this.search,
        fetcherMethod: "agent/fetchAntenneAgentConseillerWithDeStatistics",
        dataGetter: "agent/agentWithStatistics",
        paginationGetter: "agent/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);
          return `dateDeb=${dateDeb}&dateFin=${dateFin}`;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-chip {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #212529;
  cursor: pointer;
}
.custom-chip:hover {
  color: #212529;
  cursor: pointer;
}
.custom-chip.active {
  background: #007bff !important;
  color: white;
  font-weight: bold;
}
</style>
